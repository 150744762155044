import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Welcome from './Welcome';
import Login from './Login';
import Activation from './Activation';
import Verification from './Verification';


const setCookie = (name, value, expiryMinutes) => {
  const d = new Date();
  d.setTime(d.getTime() + (expiryMinutes * 60 * 1000));
  document.cookie = `${name}=${encodeURIComponent(value)};expires=${d.toUTCString()};path=/`;
};


function Application() {
  useEffect(() => {
    // force a reload on root path, which should load the application if we have logged in
    window.location.href = '/';
  });

  return (
    <div>Application</div>
  );
}

function Logout() {
  useEffect(() => {
    // clear our cookie if it is present
    console.log('Cookie: ' + document.cookie);
    setCookie('xyzzy', '', 0);
    console.log('clearing refresh token');
    sessionStorage.removeItem('refresh_token');
    window.location.href = '/';
  });

  return (
    <div>Logging out</div>
  );
}

function ActivateAccount() {
  return (
    <Activation purpose="ActivateAccount" />
  );
}

function ResetPassword() {
  return (
    <Activation purpose="ResetPassword" />
  );
}

function VerifyEmail() {
  return (
    <Verification purpose="VerifyEmail" />
  );
}

function VerifyCellPhone() {
  return (
    <Verification purpose="VerifyCellPhone" />
  );
}

function Done() {
  useEffect(() => {
    window.close();
  }, []);

  return (
    <div>Please close this browser tab</div>
  );
}

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Welcome}/>
        <Route path="/done" Component={Done}/>
        <Route path="/login" Component={Login}/>
        <Route path="/logout" Component={Logout}/>
        <Route path="/auth/activate_account" Component={ActivateAccount}/>
        <Route path="/auth/reset_password" Component={ResetPassword}/>
        <Route path="/verify/email" Component={VerifyEmail}/>
        <Route path="/verify/cell_number" Component={VerifyCellPhone}/>
        <Route path="/application" Component={Application}/>
        <Route render={() => <h1>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
