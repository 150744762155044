import React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';


function Welcome() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/login');
  };

  return (
    <Grid
      role="main"
      style={{
        width: '100%',
        maxWidth: 360,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 180,
        textAlign: 'center',
        border: '2px solid blue',
        padding: '20px',
      }}
    >
      <Typography variant="h2" style={{ color: '#2E384D', fontWeight: 600 }}>
        BHS
      </Typography>
      <Typography variant="h4" style={{ color: '#2E384D' }}>
        Welcome
      </Typography>
      <Grid item container>
        <Button
          style={{
            background: '#2E5BFF',
            padding: '13px 0px',
            textTransform: 'inherit',
            width: '100%',
            fontSize: 15,
            marginTop: 18,
          }}
          name="Welcome"
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          Click to Login
        </Button>
      </Grid>
    </Grid>
  );
}


export default Welcome;
