import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AlertModal from './AlertModal';


Verification.propTypes = {
  purpose: PropTypes.string.isRequired,
};

function Verification(props) {
  const { purpose } = props;

  const history = useNavigate();
  const location = useLocation();

  const initialValues = {
    accept: false,
  };
  const [values, setValues] = useState(initialValues);
  const [alertMessage, setAlertMessage] = useState(null);
  const [handleAlertClose, setHandleAlertClose] = useState(null);
  const [verificationToken, setVerificationToken] = useState(null);

  useEffect(() => {
    console.log('location: ', location.pathname, location.search);
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (!token) {
      history.replace('/done');
      return;
    }
    fetch(`/api/verification?token=${encodeURIComponent(token)}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'GET',
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`(${response.status} - ${response.statusText})`);
          }
          return response.text();
        })
        .then((body) => {
          return JSON.parse(body);
        })
        .then((data) => {
          if (!data || data.status !== 'valid') {
            throw new Error('Invalid token');
          }
          setVerificationToken(token);
        })
        .catch((_err) => {
          // setting state requires using a function returning a function
          setHandleAlertClose(() => () => {
            setAlertMessage(null);
            history.replace('/done');
          });
          setAlertMessage('Link is invalid or has expired');
        });
  }, [location, history]);

  /*
  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  */

  const onCheckboxChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    let action;
    switch (purpose) {
      case 'VerifyEmail':
        action = 'Email verification';
        break;
      case 'VerifyCellPhone':
        action = 'Cell phone verification';
        break;
      default:
        action = 'Verification';
        break;
    }

    if (!values.accept) {
      setAlertMessage(`${action} verification cannot proceed without accepting receipt of notifications`);
      return;
    }

    fetch('/api/verification', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        verification_token: verificationToken,
        accept: values.accept,
      }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`(${response.status} - ${response.statusText})`);
          }
          return response.text();
        })
        .then((body) => {
          return JSON.parse(body);
        })
        .then(() => {
          // setting state requires using a function returning a function
          setHandleAlertClose(() => () => {
            setAlertMessage(null);
            history.replace('/done');
          });
          let message;
          switch (purpose) {
            case 'VerifyEmail':
              message = 'Your email address has been verified';
              break;
            case 'VerifyCellPhone':
              message = 'Your cell phone number has been verified';
              break;
            default:
              message = '';
              break;
          }
          setAlertMessage(message);
        })
        .catch((err) => {
          setAlertMessage(`${action} failed: ${err.message}`);
        });
  };

  const onAlertClose = () => {
    setAlertMessage(null);
    if (handleAlertClose) {
      handleAlertClose();
    }
  };

  return (<div>
    <AlertModal
      open={!!alertMessage}
      onClose={onAlertClose}
      message={alertMessage}
    />
    <Grid
      role="main"
      style={{
        width: '100%',
        maxWidth: 360,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 180,
        textAlign: 'center',
        border: '2px solid blue',
        padding: '20px',
      }}
    >
      <Typography variant="h2" style={{ color: '#2E384D', fontWeight: 600 }}>
        BHS
      </Typography>
      <Typography variant="h4" style={{ color: '#2E384D' }}>
        {
          (purpose === 'VerifyEmail') ? 'Email Verification' :
          (purpose === 'VerifyCellPhone') ? 'Cell Phone Verification' :
          'Verification'
        }
      </Typography>
      <form style={{ marginTop: 15 }} onSubmit={onSubmit}>
        <Grid container direction="column" gap={3}>
          <Grid item>
            <Typography variant="body2">
              {'Please click the checkbox below acknowledging that you accept receiving Remy notifications' +
                (
                  (purpose === 'VerifyEmail') ? ' via email' :
                  (purpose === 'VerifyCellPhone') ? ' via Text to your cell phone' :
                  ''
                )
              }
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.accept}
                  onChange={onCheckboxChange}
                  name="accept"
                  color="primary"
                  required
                />
              }
              label="I accept"
            />
          </Grid>
          <Grid item container>
            <Button
              style={{
                background: '#2E5BFF',
                padding: '13px 0px',
                textTransform: 'inherit',
                width: '100%',
                fontSize: 15,
                marginTop: 18,
              }}
              name="activate"
              type="submit"
              variant="contained"
              color="primary"
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  </div>);
}


export default Verification;
