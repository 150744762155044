import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import AlertModal from './AlertModal';


const textFieldStyle = {
  marginTop: 15,
  background: 'rgba(224, 231, 255, 0.2)',
  fontSize: 15,
  color: '#2E384D',
  width: '100%',
};


Activation.propTypes = {
  purpose: PropTypes.string.isRequired,
};

function Activation(props) {
  const { purpose } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    username: '',
    temp_password: '',
    password: '',
    password_confirm: '',
  };
  const [values, setValues] = useState(initialValues);
  const [alertMessage, setAlertMessage] = useState(null);
  const [handleAlertClose, setHandleAlertClose] = useState(null);
  const [activationToken, setActivationToken] = useState(null);

  useEffect(() => {
    console.log('location: ', location.pathname, location.search);
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (!token) {
      navigate('/login', { replace: true });
      return;
    }
    fetch(`/api/activate?token=${encodeURIComponent(token)}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'GET',
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`(${response.status} - ${response.statusText})`);
          }
          return response.text();
        })
        .then((body) => {
          return JSON.parse(body);
        })
        .then((data) => {
          if (!data || data.status !== 'valid') {
            throw new Error('Invalid token');
          }
          setActivationToken(token);
        })
        .catch(() => {
          // setting state requires using a function returning a function
          setHandleAlertClose(() => () => {
            setAlertMessage(null);
            navigate('/login', { replace: true });
          });
          setAlertMessage('Link is invalid or has expired');
        });
  }, [location, navigate]);

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const checkPassword = (password) => {
    const checks = [
      /[a-z]/,
      /[A-Z]/,
      /[0-9]/,
      /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/, // as per https://owasp.org/www-community/password-special-characters
    ];
    let count = 0;
    for (const check of checks) {
      if (check.test(password)) {
        ++count;
      }
    }
    return password.length >= 10 && count >= 3;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (values.password !== values.password_confirm) {
      setAlertMessage('Passwords do not match');
      return;
    }

    if (!checkPassword(values.password)) {
      setAlertMessage(
          'Password must be at least 10 characters long ' +
          'and contain at least 3 of 4 of: digits, lower case letters, ' +
          'upper case letters, special characters'
      );
      return;
    }

    fetch('/api/activate', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        id: values.username,
        activation_token: activationToken,
        temp_password: values.temp_password,
        password: values.password,
      }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`(${response.status} - ${response.statusText})`);
          }
          return response.text();
        })
        .then((body) => {
          return JSON.parse(body);
        })
        .then(() => {
          // setting state requires using a function returning a function
          setHandleAlertClose(() => () => {
            setAlertMessage(null);
            navigate('/login', { replace: true });
          });
          let message;
          switch (purpose) {
            case 'ActivateAccount':
              message = 'Your account has been successfully activated. ';
              break;
            case 'ResetPassword':
              message = 'Your password has been successfully changed. ';
              break;
            default:
              message = '';
              break;
          }
          setAlertMessage(message + 'You will now be forwarded to the Login screen');
        })
        .catch((err) => {
          let action;
          switch (purpose) {
            case 'ActivateAccount':
              action = 'Account activation';
              break;
            case 'ResetPassword':
              action = 'Password change';
              break;
            default:
              action = 'Action';
              break;
          }
          setAlertMessage(`${action} failed: ${err.message}`);
        });
  };

  const onAlertClose = () => {
    setAlertMessage(null);
    if (handleAlertClose) {
      handleAlertClose();
    }
  };

  return (<div>
    <AlertModal
      open={!!alertMessage}
      onClose={onAlertClose}
      message={alertMessage}
    />
    <Grid
      role="main"
      style={{
        width: '100%',
        maxWidth: 360,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 180,
        textAlign: 'center',
        border: '2px solid blue',
        padding: '20px',
      }}
    >
      <Typography variant="h2" style={{ color: '#2E384D', fontWeight: 600 }}>
        BHS
      </Typography>
      <Typography variant="h4" style={{ color: '#2E384D' }}>
        {
          (purpose === 'ActivateAccount') ? 'Activate Account' :
          (purpose === 'ResetPassword') ? 'Reset Password' :
          'Activation'
        }
      </Typography>
      <form style={{ marginTop: 15 }} onSubmit={onSubmit}>
        <Grid container direction="column" gap={3}>
          <Grid item>
            <TextField
              style={textFieldStyle}
              variant="outlined"
              label="Username"
              name="username"
              required
              value={values.username}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={textFieldStyle}
              variant="outlined"
              label="Temporary Password"
              name="temp_password"
              type="password"
              required
              onChange={onChange}
              value={values.temp_password}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Divider />
          <Grid item>
            <Typography variant="body2">
              Please enter a new password below. The password must be at least 10 characters long and contain
              at least 3 of 4 of: digits, lower case letters, upper case letters, special characters.
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              style={textFieldStyle}
              variant="outlined"
              label="Password"
              name="password"
              type="password"
              required
              onChange={onChange}
              value={values.password}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={textFieldStyle}
              variant="outlined"
              label="Confirm Password"
              name="password_confirm"
              type="password"
              required
              onChange={onChange}
              value={values.password_confirm}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item container>
            <Button
              style={{
                background: '#2E5BFF',
                padding: '13px 0px',
                textTransform: 'inherit',
                width: '100%',
                fontSize: 15,
                marginTop: 18,
              }}
              name="activate"
              type="submit"
              variant="contained"
              color="primary"
            >
              {
                (purpose === 'ActivateAccount') ? 'Activate' :
                (purpose === 'ResetPassword') ? 'Set Password' :
                'Activate'
              }
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  </div>);
}


export default Activation;
