import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
};

function AlertModal(props) {
  const { open, onClose, message } = props;

  const multiline = message ? message.includes('\n') : false;
  const s = multiline ? message.split('\n').join('<br/>') : message;

  const onDialogClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      //disableBackdropClick
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Alert</DialogTitle>
      <DialogContent dividers>
        <Typography
          style={{ textAlign: multiline ? 'left' : 'center' }}
          dangerouslySetInnerHTML={{ __html: s }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" key={1} onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default AlertModal;
