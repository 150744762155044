import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import AlertModal from './AlertModal';
import logo from './images/BHS_dark.svg';

const textFieldStyle = {
  marginTop: 15,
  background: 'rgba(224, 231, 255, 0.2)',
  fontSize: 15,
  color: '#2E384D',
  width: '100%',
};

function Login() {
  const navigate = useNavigate();

  const initialValues = {
    username: '',
    password: '',
  };
  const [values, setValues] = useState(initialValues);
  const [alertMessage, setAlertMessage] = useState(null);

  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    fetch('/api/login', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ username: values.username, password: values.password }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`(${response.status} - ${response.statusText})`);
          }
          return response.text();
        })
        .then((body) => {
          return JSON.parse(body);
        })
        .then((data) => {
          if (!data.refresh_token) {
            throw new Error('Invalid response');
          }
          console.log('Saving refresh token');
          sessionStorage.setItem('refresh_token', data.refresh_token);
          console.log('Jump to application');
          navigate('/application');
        })
        .catch((err) => {
          setAlertMessage('Login failed: ' + err.message);
        });
  };

  return (<div>
    <AlertModal
      open={!!alertMessage}
      onClose={() => setAlertMessage(null)}
      message={alertMessage}
    />
    <Grid
      role="main"
      style={{
        width: '100%',
        maxWidth: 360,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 180,
        textAlign: 'center',
        border: '2px solid blue',
        padding: '20px',
      }}
    >
      <img alt="" src={logo} />
      <Typography variant="h4" style={{ marginTop: '20px', color: '#2E384D' }}>
        Please Login
      </Typography>
      <form style={{ marginTop: 15 }} onSubmit={onSubmit}>
        <Grid container direction="column" gap={3}>
          <Grid item>
            <TextField
              style={textFieldStyle}
              variant="outlined"
              label="Username"
              name="username"
              required
              value={values.username}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={textFieldStyle}
              variant="outlined"
              label="Password"
              name="password"
              type="password"
              required
              onChange={onChange}
              value={values.password}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item container>
            <Button
              style={{
                background: '#2E5BFF',
                padding: '13px 0px',
                textTransform: 'inherit',
                width: '100%',
                fontSize: 15,
                marginTop: 18,
              }}
              name="login"
              type="submit"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  </div>);
}


export default Login;
